const toDataURL = url => {
	return fetch(url)
	.then((response) => {
		return response.blob();
	})
	.then(blob => {
		return URL.createObjectURL(blob);
	});
}

async function downloadImage(url, name) {
	const a = document.createElement("a");
	a.href = await toDataURL(url);
	a.download = name || 'image.jpg';
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export default downloadImage;