import React, { useState } from 'react';
import sliderRightArrow from '../images/slider-right-arrow.png'
import sliderLeftArrow from '../images/slider-left-arrow.png'
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import Picture from './Picture';


SwiperCore.use([EffectCoverflow, Pagination]);

export default class ImageSliderSwiperjs extends React.Component {
    constructor(props) {
      super(props)
      this.nextSlide = this.nextSlide.bind(this)
      this.prevSlide = this.prevSlide.bind(this)
      this.swiper = null
      this.state = {current: 0};
    }
   
    nextSlide() {
      if (this.swiper) this.swiper.slideNext()
    }
   
    prevSlide() {
      if (this.swiper) this.swiper.slidePrev()
    }

   
    render() {
   
      const slides =this.props.slides
      return(
        <section className= {`sliders-swiperjs ${this.props.showControls ? "hide-dots" : ""}`}>

            <Swiper id="my-swiper"
                pagination={true} 
                modules={[Pagination]}
                effect={"fade"}
                loop={true}
                grabCursor={false}
                centeredSlides={true}
                slidesPerView={"auto"}                
                // effect={"coverflow"}
                coverflowEffect={{
                    rotate: 20,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                }}
                runCallbacksOnInit={true}
                onInit={ (swiper) => {
                   this.swiper = swiper
                  }
                }
                onSlideChange={ (swiper) => {
                    this.setState({current : swiper.realIndex})
                   }
                }
            >
                {slides.map((slide, index) => {
                    return (
                      <SwiperSlide key={slide.image}>
                        <Picture src={slide.image} srcSet={slide.imageSrcSet} alt={slide.caption} className="image  slider-img" />
                      </SwiperSlide>  
                    );
                })}
            </Swiper>
            <div className={`slider-controls ${this.props.showControls ? "controls-on" : "only-caption"}`}>
                <div className='slider-numbers'>{this.state.current + 1}-{slides.length}</div>
                <div className='slider-text' >{slides[this.state.current]?.caption}</div>

                <img src={sliderLeftArrow} className='left-arrow' onClick={this.prevSlide} />
                <img src={sliderRightArrow} className='right-arrow' onClick={this.nextSlide} />
            </div>

        </section>
      )
    }
  }