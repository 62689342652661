import { Link } from 'gatsby';
import React from 'react'
import downloadImage from '../helpers/downloadImage';

const Picture = ({ 
    className,
    src,
    srcSet,
    alt,
    sizes,
    ref,
    key,
    dataCustomImages2dIndex,
    linksTo,
    linkClassName='',
    linkTarget='_self',
    noDownload=false,
    useSlimButton=false }) => {

    const displayedSources = [];    

    if (srcSet !== undefined) {
        const sourceSets = srcSet.split(', ');
        sourceSets.forEach(source => {
            const splitSet = source.split(' ');
            if (splitSet.length > 1)
                displayedSources.push(<source media={`(min-width:${splitSet[1]})`} srcset={splitSet[0]}></source>);
        });
    }

    const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onDownloadClick();
		}
	}

	const onDownloadClick = () => {
		let filename = src;
		if (filename.includes("prismic"))
			filename = filename.substring(71,filename.length).split('?')[0];

        filename = decodeURIComponent(filename);
		downloadImage(src, filename);
	}

    const internalImageComponent = <img
        className={className}
        src={src}
        srcSet={srcSet}
        alt={alt}
        sizes={sizes}
        ref={ref}
        key={key}
        data-custom-images-2d-index={dataCustomImages2dIndex}
    />;

    const pictureComponent = noDownload ? <picture>               
        {displayedSources}
        { linksTo && <Link to={linksTo} target={linkTarget} className={linkClassName}>{internalImageComponent}</Link> }
        { !linksTo && internalImageComponent }
    </picture> : 
    <div className='picture-wrapper'>
        <div
            className={`DownloadableImage__Button${useSlimButton ? ' slim-dl-button' : ''}`}
            onClick={onDownloadClick}
            onKeyDown={handleOnKeyDown}
            role="button"
            tabIndex={0}
            data-cursor-hide
        >
            { !useSlimButton && <span className="DownloadableImage__ButtonText">Download Image</span> }
            <svg className="DownloadableImage__ButtonIcon" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff"><path d="M4 0v7.188M.277 3.884L4 7.474l3.724-3.59"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(90 4 4)" d="M0 0h8v8H0z"/></clipPath></defs></svg>
        </div>
        
        <picture>
            {displayedSources}
            { linksTo && <Link to={linksTo} className={linkClassName}>{internalImageComponent}</Link> }
            { !linksTo && internalImageComponent }
        </picture>
    </div>;

    return pictureComponent;
}

export default Picture;
